import React from "react";
import { Box, Typography, Link } from "@mui/material";

const HIZMETLERIMIZ = [
  { name: "Ürünler", link: "/urunler" },
  { name: "Isı Pompası", link: "/isi-pompasi" },
  { name: "Klima", link: "/klima" },
  { name: "VRF Sistemleri", link: "/vrf" },
  { name: "Havuz Isı Pompası", link: "/havuz-isi-pompasi" },
  { name: "Petek Tesisatı", link: "/petek-tesisati" },
  { name: "Yerden Isıtma", link: "/yerden-isitma" },
  { name: "Servis", link: "/servis" },
];

const Services = () => (
  <Box sx={{ "& a": { textDecoration: "none" } }}>
    {HIZMETLERIMIZ.map((service, index) => (
      <Link
        href={service.link}
        key={index}
        underline="none"
        sx={{
          color: "primary.main",
          "&:hover": {
            color: "text.secondary",
            textDecoration: "none",
          },
        }}
      >
        <Typography>{service.name}</Typography>
      </Link>
    ))}
  </Box>
);

export default Services;

import React from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  CardMedia,
} from "@mui/material";
import theme from "../../../assets/createTheme";
// Yerden ısıtma sistemi ile ilgili bir resim yolu ekleyin
import floorHeatingImage from "../../../assets/Hizmetler/yerden isitma.jpg";

const FloorHeatingInfo = () => {
  const sections = [
    {
      title: "Yerden Isıtma Sistemi Nedir?",
      descriptions: [
        "Yerden ısıtma sistemi, zemin altına yerleştirilen borular aracılığıyla ısıyı homojen bir şekilde dağıtarak mekanları ısıtan modern ve verimli bir ısıtma çözümüdür. Isı, ayaklarınızın altından yukarıya doğru yayılarak mekanın eşit şekilde ısınmasını sağlar.",
        "Bu sistem, geleneksel radyatörlerin aksine, ısıyı doğrudan yaşam alanına dağıtarak enerji verimliliğini artırır ve ısı kaybını azaltır.",
      ],
      image: floorHeatingImage,
    },
    {
      title: "Yerden Isıtma Sistemlerinin Avantajları",
      descriptions: [
        "Enerji verimliliği yüksektir, ısı kaybı minimum seviyededir.",
        "Isı dağılımı eşit olduğundan dolayı konforlu bir yaşam alanı sunar.",
        "Görünürde radyatör veya ısıtıcı bulunmadığı için estetik bir görünüm sağlar.",
        "Toz ve alerjen yayılımını azaltır, daha sağlıklı bir yaşam ortamı oluşturur.",
      ],
    },
    {
      title: "Yerden Isıtma Sistemi Uygulama Alanları",
      descriptions: [
        "Konutlar, ofisler, oteller ve alışveriş merkezleri gibi birçok farklı yapıda kullanılabilir.",
        "Yenileme projelerinde veya yeni inşaatlarda kolaylıkla entegre edilebilir.",
      ],
    },
  ];

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Yerden Isıtma Sistemleri Hakkında Bilgi
      </Typography>
      <List>
        {sections.map((section, index) => (
          <ListItem
            alignItems="flex-start"
            key={index}
            sx={{ borderBottom: 1, borderColor: "divider", py: 2 }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold" }}
                >
                  {section.title}
                </Typography>
              }
              secondary={
                <>
                  {section.descriptions.map((desc, descIndex) => (
                    <Typography
                      key={descIndex}
                      variant="body1"
                      component="p"
                      sx={{ mt: 1, color: theme.palette.text.primary }}
                    >
                      {desc}
                    </Typography>
                  ))}
                  {section.image && (
                    <CardMedia
                      component="img"
                      image={section.image}
                      alt={`Yerden Isıtma Sistemi Resmi`}
                      sx={{ height: "600px", mt: 2 }}
                    />
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default FloorHeatingInfo;

import React from "react";
import {
  AppBar,
  CssBaseline,
  Toolbar,
  Button,
  Stack,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom"; // React Router'dan Link bileşenini import edin
import NavbarMenu from "./NavbarMenu";
import Logo from "../../../assets/LogoForNavbar2.png";

export default function ResponsiveStack() {
  const theme = useTheme(); // Temayı kullanmak için

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: theme.palette.background2.default,
        }}
      >
        <Toolbar>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%", maxWidth: 1280, margin: "0 auto" }} // Navbar içeriğini ortalamak ve genişliğini sınırlamak için
          >
            {/* Logoya tıklandığında anasayfaya yönlendirme */}
            <Button component={Link} to="/" sx={{ padding: 0 }}>
              <img
                src={Logo}
                alt="logo"
                style={{ maxHeight: 50, cursor: "pointer" }}
              />
            </Button>

            {/* NavbarMenu bileşenine React Router Link kullanımını entegre edin */}
            <NavbarMenu />

            {/* "Ücretsiz Keşif" butonu ile sayfa değişikliği */}
            <Button component={Link} to="/ucretsiz-kesif" variant="contained">
              Ücretsiz Keşif
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      {/* AppBar'ın yüksekliği kadar boşluk bırakmak için Toolbar ekleyin */}
      <Toolbar />
    </React.Fragment>
  );
}

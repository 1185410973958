import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Grid,
  Button,
  Modal,
  CardActionArea,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ProductItem({ product, index }) {
  const [open, setOpen] = useState(false); // State for modal open/close

  const handleOpen = () => setOpen(true); // Open modal
  const handleClose = () => setOpen(false); // Close modal

  const settings = {
    dots: true,
    infinite: product.foto.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true, // Adjust slider height based on the image
  };

  // Modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "90%", // Full width on extra-small screens
      sm: "80%", // Slightly smaller on small screens
      md: "70%", // and so on
      lg: "60%",
      xl: "50%",
    },
    maxWidth: "100%", // Ensure modal does not exceed screen width
    maxHeight: "90vh", // Maximum height to ensure it does not exceed screen height
    overflowY: "auto", // Allow scrolling for overflow content
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
  };

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Card
        sx={{ width: "100%", height: "100%", margin: "auto" }}
        onClick={handleOpen}
      >
        <CardActionArea>
          <Box>
            {/* Display first image or "No Images Available" text */}
            {Array.isArray(product.foto) && product.foto.length > 0 ? (
              <img
                src={product.foto[0]}
                alt={`${product.model} - 1`}
                style={{ width: "100%", height: "200px", margin: "10px 0" }}
              />
            ) : (
              <Typography
                sx={{
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Images Available
              </Typography>
            )}
          </Box>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {product.model}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

      {/* Modal for additional details including image carousel */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Slider {...settings}>
            {Array.isArray(product.foto) && product.foto.length > 0 ? (
              product.foto.map((foto, idx) => (
                <div key={`${foto}-${idx}`}>
                  <img
                    src={foto}
                    alt={`${product.model} - ${idx + 1}`}
                    style={{ width: "100%", display: "block", margin: "auto" }}
                  />
                </div>
              ))
            ) : (
              <Typography>No Images Available</Typography>
            )}
          </Slider>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            {product.model}
          </Typography>
          <Box display="flex" flexDirection="column" gap={1} marginTop={2}>
            <Chip label={`Tip: ${product.tip}`} color="primary" />
            <Chip label={`BTU: ${product.btuKapasiteleri}`} color="secondary" />
            <Chip label={`Enerji: ${product.enerjiSinifi}`} color="success" />
            {/* Additional product details */}
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
}

export default ProductItem;

import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Container, // Container import edildi
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import img1 from "../../../assets/UrunFoto/zess isi pompasi gold series sade kopya 2.jpg";
import img2 from "../../../assets/NasilCalisir/split.jpg";
import img3 from "../../../assets/Hizmetler/montajin onemi.jpg";

const Urunler = () => {
  const navigate = useNavigate();

  const urunler = [
    {
      id: 1,
      adi: "Isı Pompası Nedir ?",
      resim: img2,
      link: "/isi-pompasi/isi-pompasi-nedir",
    },
    {
      id: 2,
      adi: "Isı Pompaları",
      resim: img1,
      link: "/isi-pompasi/isi-pompalari",
    },
    {
      id: 3,
      adi: "Montaj Öncesi ve Sonrası Yapılması Gerekenler",
      resim: img3,
      link: "/isipompasi/montaj-oncesi-ve-sonrasi-yapilmasi-gerekenler",
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {" "}
      {/* Container ekleyerek içeriği merkezde ortaladık */}
      <Grid container spacing={2} justifyContent="center">
        {" "}
        {/* justifyContent ile yatay olarak ortaladık */}
        {urunler.map((urun) => (
          <Grid item xs={12} sm={6} md={4} key={urun.id}>
            <Card
              sx={{
                maxWidth: 345,
                bgcolor: "background.white",
                ":hover": {
                  boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                },
                transition: "0.3s",
              }}
            >
              <CardActionArea onClick={() => navigate(urun.link)}>
                <CardMedia
                  component="img"
                  height="205"
                  image={urun.resim}
                  alt={urun.adi}
                />
                <CardContent sx={{ bgcolor: "background.default" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ color: "text.primary", fontWeight: "bold" }}
                  >
                    {urun.adi}
                  </Typography>
                  {urun.aciklama && (
                    <Typography variant="body2" color="text.secondary">
                      {urun.aciklama}
                    </Typography>
                  )}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Urunler;

import React from "react";
import { Typography, Container, Grid, Card, CardContent } from "@mui/material";

const Kampanyalar = () => {
  const offers = [
    {
      title: "Kışa Özel Isı Pompası Kampanyası",
      description:
        "Soğuk kış günlerinde evinizi veya iş yerinizi ekonomik ve verimli bir şekilde ısıtmak için kışa özel ısı pompası kampanyalarımızı kaçırmayın!",
    },
    {
      title: "Eski Sisteminizi Yenileyin, %20 İndirim Kazanın",
      description:
        "Eski ısı pompası sisteminizi yenisiyle değiştirin, %20 indirim fırsatından yararlanın. Daha yüksek verimlilik ve daha düşük enerji maliyetleri için hemen başvurun!",
    },
    {
      title: "Ücretsiz Keşif ve Montaj Fırsatı",
      description:
        "Şimdi ısı pompası satın alın, ücretsiz keşif ve montaj hizmetimizden faydalanın. Konforlu ve ekonomik bir ısıtma çözümü için en doğru zaman!",
    },
  ];

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ mb: 4, mt: 4 }}
      >
        Isı Pompası Kampanyaları
      </Typography>
      <Grid container spacing={4}>
        {offers.map((offer, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div">
                  {offer.title}
                </Typography>
                <Typography variant="body2" color="text.primary">
                  {offer.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Kampanyalar;

import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography, useTheme, Stack } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Link } from "react-router-dom"; // React Router'dan Link'i import edin
import img1 from "../../../assets/UrunFoto/zess isi pompasi silver series.jpg";
import img2 from "../../../assets/Hizmetler/isinma 2.jpg";
import img3 from "../../../assets/UrunFoto/zess isi pompasi gold series sade kopya 2.jpg";

const carouselItems = [
  {
    imgPath: img3,
    description: "Zess Isı Pompası Gold Series",
    link: "/urunler/isi-pompalari",
  },
  {
    imgPath: img1,
    description: "Zess Isı Pompası Silver Series",
    link: "/urunler/isi-pompalari",
  },
  {
    imgPath: img2,
    description: "Isınma Problemlerı Çözümü",
    link: "/ucretsiz-kesif",
  },
];

const Carousel = () => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = carouselItems.length;
  const theme = useTheme();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep + maxSteps - 1) % maxSteps
    );
  };

  const handleStepChange = (step) => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    const timer = setInterval(handleNext, 4000);
    return () => {
      clearInterval(timer);
    };
  }, [activeStep]);

  return (
    <Box
      sx={{
        position: "relative",
        maxWidth: "60vw",
        height: "500px",
        margin: "auto",
        overflow: "hidden",
        "& img": {
          transition: "transform 0.5s ease",
          width: "100%",
          maxHeight: "400px",
          objectFit: "contain",
        },
        [theme.breakpoints.down("md")]: {
          maxWidth: "90vw",
        },
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100vw",
        },
        "&:hover img": {
          transform: "scale(1.05)",
        },
      }}
    >
      {carouselItems.map((item, index) => (
        <Box
          key={index}
          component="img"
          src={item.imgPath}
          alt={`carousel image ${index + 1}`}
          sx={{
            display: index === activeStep ? "block" : "none",
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      ))}
      <Link
        to={carouselItems[activeStep].link}
        style={{ textDecoration: "none" }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            position: "absolute",
            bottom: {
              xs: 10, // Çok küçük ekranlar için
              sm: 13, // Küçük ekranlar için
              md: 41, // Orta boy ekranlar için
              lg: 41, // Büyük ekranlar için
              xl: 30, // Çok büyük ekranlar için
            },
            left: "50%",
            transform: "translateX(-50%)",
            color: theme.palette.text.secondary,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "10px",
            borderRadius: "5px",
            width: "30vw",
            fontWeight: "bold",
            textAlign: "center", // Metni ortalar
            cursor: "pointer", // Fare imlecini link üzerine geldiğinde değiştirir
          }}
        >
          {carouselItems[activeStep].description}
        </Typography>
      </Link>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={0.1}
        sx={{
          position: "absolute",
          bottom: {
            xs: 110, // Çok küçük ekranlar için
            sm: 90, // Küçük ekranlar için
            md: 85, // Orta boy ekranlar için
            lg: 80, // Büyük ekranlar için
            xl: 85, // Çok büyük ekranlar için
          },
          width: "100%",
          color: "white",
        }}
      >
        {carouselItems.map((item, index) => (
          <IconButton
            key={index}
            onClick={handleStepChange(index)}
            size="small"
            sx={{
              color:
                activeStep === index
                  ? theme.palette.background.secondary
                  : theme.palette.background.white,
              "&:hover": {
                color:
                  activeStep === index
                    ? theme.palette.text.secondary
                    : theme.palette.background.white,
              },
            }}
          >
            <FiberManualRecordIcon fontSize="small" />
          </IconButton>
        ))}
      </Stack>
      <IconButton
        onClick={handleBack}
        sx={{
          position: "absolute",
          top: "50%",
          left: 16,
          transform: "translateY(-50%)",
          color: theme.palette.blue.main,
          backgroundColor: theme.palette.background.default,
          "&:hover": {
            backgroundColor: theme.palette.blue.main,
            color: theme.palette.ice.orange,
          },
        }}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          top: "50%",
          right: 16,
          transform: "translateY(-50%)",
          color: theme.palette.blue.main,
          backgroundColor: theme.palette.background.default,
          "&:hover": {
            backgroundColor: theme.palette.blue.main,
            color: theme.palette.ice.orange,
          },
        }}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

export default Carousel;

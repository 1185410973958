import React from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  CardMedia,
} from "@mui/material";
// import theme from "../../../assets/createTheme";
// import vrfSystemImage from "../../../assets/VrfSistemleri/vrf-system.jpg"; // VRF ait bir resim varsayıyorum
// import FaqSection from "./FaqSection"; // FAQ bölümü için bir bileşen varsayıyorum
import theme from "../../../assets/createTheme";

const VrfNedir = () => {
  const sections = [
    {
      title: "VRF Sistemi Nedir?",
      descriptions: [
        "VRF (Variable Refrigerant Flow), değişken soğutucu akışkan debisi anlamına gelir ve genellikle büyük binaların iklimlendirme ihtiyaçlarını karşılamak için kullanılan gelişmiş bir teknolojidir. Bu sistemler, soğutucu akışkanın debisini, ihtiyaç duyulan soğutma veya ısıtma miktarına göre otomatik olarak ayarlayabilir.",
      ],
      // image: vrfSystemImage,s
    },
    {
      title: "VRF Sistemlerinin Çalışma Prensibi",
      descriptions: [
        "VRF sistemleri, merkezi bir dış ünite ve birden fazla iç üniteyi içerir. Dış ünite, soğutucu akışkanı iç ünitelere taşır ve böylece bina içindeki farklı alanların ayrı ayrı ısıtılmasını veya soğutulmasını sağlar. Sistem, ihtiyaç duyulan enerjiyi optimize eder ve enerji tüketimini minimize eder.",
      ],
    },
    {
      title: "VRF Sistemlerinin Avantajları",
      descriptions: [
        "Enerji verimliliği, esnek kurulum seçenekleri, sessiz çalışma, uzun ömür ve bakım kolaylığı gibi birçok avantajı vardır.",
      ],
    },
    {
      title: "VRF Sistemleri Nerelerde Kullanılır?",
      descriptions: [
        "Ofis binaları, oteller, hastaneler, okullar ve büyük konut projeleri gibi birçok farklı türdeki yapıda kullanılır.",
      ],
    },
  ];

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        VRF Sistemleri Hakkında Bilgi
      </Typography>
      <List>
        {sections.map((section, index) => (
          <ListItem
            alignItems="flex-start"
            key={index}
            sx={{ borderBottom: 1, borderColor: "divider", py: 2 }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold" }}
                >
                  {section.title}
                </Typography>
              }
              secondary={
                <>
                  {section.descriptions.map((desc, descIndex) => (
                    <Typography
                      key={descIndex}
                      variant="body1"
                      component="p"
                      sx={{ mt: 1, color: theme.palette.text.primary }}
                    >
                      {desc}
                    </Typography>
                  ))}
                  {section.image && (
                    <CardMedia
                      component="img"
                      image={section.image}
                      alt={`Section Image ${index}`}
                      sx={{ width: "100%", mt: 2 }}
                    />
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
      {/* <FaqSection /> */}
    </Container>
  );
};

export default VrfNedir;

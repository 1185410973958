import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom"; // React Router'dan Link bileşenini import edin

const YASALICERIKLER = [
  {
    name: "Kişisel Verilerin Korunması ve İşlenmesi Politikası",
    link: "/kisisel-veriler",
  },
  { name: "Kullanım Koşulları ve İletişim", link: "/kullanim-kosullari" },
  {
    name: "Çerez ve Gizlilik Politikası",
    link: "/cerez-ve-gizlilik-politikasi",
  },
  { name: "Yasal Uyarılar ve Gereklilikler", link: "/yasal-uyarilar" },
  { name: "Müşteri Memnuniyeti", link: "/musteri-memnuniyeti" },
];

const LegalLinks = () => (
  <Box>
    {YASALICERIKLER.map((item, index) => (
      <Button
        key={index}
        component={Link} // Button bileşenini Link olarak kullan
        to={item.link} // Link'in yönlendireceği yolu belirt
        sx={{
          textDecoration: "none",
          color: "primary.main",
          "&:hover": {
            color: "text.secondary",
            textDecoration: "underline",
          },
          justifyContent: "start",
          textAlign: "left",
          width: "100%",
          textTransform: "none",
        }}
      >
        <Typography sx={{ textAlign: "center", width: "100%" }}>
          {item.name}
        </Typography>
      </Button>
    ))}
  </Box>
);

export default LegalLinks;

import React from "react";
import { Fab, useMediaQuery } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { styled, useTheme } from "@mui/material/styles";

// StyledFab bileşenini responsive yapmak için useTheme ve useMediaQuery kullanılacak
const WhatsAppButton = ({ phone }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md")); // 'sm' breakpoint altı için kontrol ediyoruz

  const StyledFab = styled(Fab)({
    position: "fixed",
    bottom: theme.spacing(matches ? 5 : 15), // Eğer küçük ekran ise daha az boşluk, değilse daha fazla boşluk
    right: theme.spacing(matches ? 2 : 4.5),
    backgroundColor: "#25D366", // WhatsApp'ın karakteristik yeşil rengi
    "&:hover": {
      backgroundColor: "#1ebe5d",
    },
    color: "white", // İkon rengini beyaz yapar
    fontSize: matches ? "1.5rem" : "1.8rem", // İkon boyutunu da ekran boyutuna göre ayarlayabiliriz
  });

  return (
    <StyledFab
      aria-label="whatsapp"
      onClick={() => window.open(`https://wa.me/${phone}`, "_blank")}
    >
      <WhatsAppIcon style={{ fontSize: "inherit" }} />{" "}
    </StyledFab>
  );
};

export default WhatsAppButton;

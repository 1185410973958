import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  CssBaseline,
  Drawer,
  List,
  Toolbar,
  useTheme,
} from "@mui/material";
import Logo from "../../../assets/LogoForNavbar2.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import NavbarAccordion from "./NavbarAccordion";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function ResponsiveStack() {
  const theme = useTheme(); // Temayı kullanmak için
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  const drawerMenu = (
    <Drawer
      anchor="right"
      open={click}
      onClose={handleClick} // Drawer dışına tıklanıldığında kapanması için
      sx={{
        "& .MuiDrawer-paper": {
          height: "100vh",
          width: "100vw",
          marginTop: "3.2rem",
        },
      }}
    >
      <List>
        {/* ListItem'dan onClick özelliğini kaldırdık. */}
        <NavbarAccordion closeMenu={handleClick} />
      </List>
    </Drawer>
  );

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: theme.palette.background2.default,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Toolbar>
            <a href="/">
              <img
                src={Logo}
                alt="logo"
                style={{ maxHeight: 45, cursor: "pointer" }}
              />
            </a>
          </Toolbar>
          {click ? (
            <CancelRoundedIcon
              style={{
                marginRight: "1.1rem",
                color: theme.palette.text.secondary,
              }}
              onClick={handleClick} // Kapatma ikonuna tıklandığında Drawer kapanacak
            />
          ) : (
            <MenuRoundedIcon
              style={{
                marginRight: "1.1rem",
                color: theme.palette.text.secondary,
              }}
              onClick={handleClick} // Menü ikonuna tıklandığında Drawer açılacak
            />
          )}
        </Stack>
        {drawerMenu}
      </AppBar>
    </>
  );
}

import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom"; // Link bileşenini import et
import montajinOnemi from "../../../assets/Hizmetler/montajin onemi.jpg";
import servisHizmeti from "../../../assets/Hizmetler/klima-servisi.jpg";
import ucretsizKesif from "../../../assets/Hizmetler/ucresizkesif.jpg";

const HomePageContent = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "16px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: theme.palette.background.white,
      color: theme.palette.text.secondary,
    },
  };

  const actionStyle = {
    mt: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  };

  // Örnek resim URL'leri ve ilgili yönlendirme yolları
  const items = [
    {
      title: "Isı Pompası Montajının Önemi",
      content:
        "Isı pompası montajı, cihazın verimliliği ve ömrü açısından kritik öneme sahiptir. Doğru montaj, enerji verimliliğini artırır, performansı optimize eder ve cihazın sessiz çalışmasını sağlar. Uzman ekiplerimiz tarafından gerçekleştirilen profesyonel montaj, daha az arıza ve bakım maliyeti, güvenli kullanım, uzun ömür ve çevreye duyarlı bir kullanım sunar.",
      img: montajinOnemi,
      link: "/isipompasi/montaj-oncesi-ve-sonrasi-yapilmasi-gerekenler",
    },
    {
      title: "Özel Servis Hizmetleri",
      content:
        "Profesyonel servis ekibimiz, ısı pompası, klima ve VRF sistemleriniz için kapsamlı servis hizmetleri sunar. Bakım, onarım ve montaj işlemleriyle cihazlarınızın verimli, güvenli ve uzun ömürlü çalışmasını sağlıyoruz. Düzenli bakım ve profesyonel montaj hizmetlerimizle enerji maliyetlerinizi düşürün, performansı artırın ve konforunuzu maksimize edin.",
      img: servisHizmeti,
      link: "/servis",
    },
    {
      title: "Ücretsiz Keşif Hizmetimiz",
      content:
        "Isı pompası, klima ve VRF sistemleriniz için sunduğumuz ücretsiz keşif hizmetiyle, enerji verimliliği yüksek ve maliyet-etkin çözümler sunuyoruz. Uzman teknik ekibimiz, mekanınızın ihtiyaçlarına en uygun sistem seçimi, ideal sıcaklık ayarları ve enerji tasarrufu sağlayacak en iyi yatırımları yapmanıza yardımcı olmak için mekanınızı detaylı bir şekilde inceler.",
      img: ucretsizKesif,
      link: "/ucretsiz-kesif",
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, mt: 4, mb: 4 }}>
      <Grid item xs={12} textAlign="center" mb={3}>
        <Button
          component={Link}
          to="/kampanyalar" // Kampanyalar sayfasının rotasına göre değiştirin
          variant="contained"
          color="primary"
          size={isSmallScreen ? "medium" : "large"}
        >
          Kampanyalarımızı Keşfedin
        </Button>
      </Grid>
      <Grid container spacing={isSmallScreen ? 2 : 4} justifyContent="center">
        {items.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={cardStyle}>
              <CardMedia
                component="img"
                height="300"
                image={item.img}
                alt={item.title}
              />
              <CardContent>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  sx={{ fontWeight: "600" }}
                >
                  {item.title}
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  {item.content}
                </Typography>
              </CardContent>
              <Box sx={actionStyle}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={item.link}
                >
                  Daha Fazla Bilgi
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HomePageContent;

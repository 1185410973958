import React from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  CardMedia,
} from "@mui/material";
import theme from "../../../assets/createTheme";
import radiatorImage from "../../../assets/Hizmetler/petek tesisati 2.jpg";

const PetekTesisati = () => {
  const sections = [
    {
      title: "Petek Tesisatı Nedir?",
      descriptions: [
        "Petek tesisatı, binalarda ısıtma sistemi olarak kullanılan, suyun ısıtıldığı ve petekler aracılığıyla odaya ısı dağıtımının yapıldığı bir sistemdir.",
      ],
      image: radiatorImage,
    },
    {
      title: "Petek Tesisatının Çalışma Prensibi Nedir?",
      descriptions: [
        "Petek tesisatında, kazan ya da kombi gibi bir ısı kaynağı tarafından ısıtılan su, borular aracılığıyla peteklere taşınır. Peteklerdeki su soğudukça, yeniden ısı kaynağına döner ve bu döngü devam eder. Böylece, ısı enerjisi odalara dağıtılmış olur.",
      ],
    },
    {
      title: "Petek Tesisatı Nasıl Verimli Kullanılır?",
      descriptions: [
        "Petek tesisatının verimli kullanımı için peteklerin düzenli aralıklarla temizlenmesi, sistemin doğru basınçta çalıştırılması ve ısı kaynağının periyodik olarak bakımının yapılması gerekmektedir.",
      ],
    },
    {
      title: "Petek Temizliği Neden Önemlidir?",
      descriptions: [
        "Zamanla peteklerin içinde biriken kir ve tortu, ısı transferini engeller ve sistemin verimliliğini düşürür. Düzenli petek temizliği, sistemin daha verimli çalışmasını sağlar ve enerji tasarrufu yapılmasına yardımcı olur.",
      ],
    },
    {
      title: "Petek Tesisatı Bakımı Nasıl Yapılır?",
      descriptions: [
        "Petek tesisatı bakımı, ısı kaynağının kontrolü, boruların ve bağlantı elemanlarının durumunun incelenmesi ve gerekirse hava alma işlemlerini içerir. Bu bakım işlemleri, sistemin uzun ömürlü ve verimli çalışmasını sağlar.",
      ],
    },
    {
      title: "Petek Tesisatında Sık Karşılaşılan Sorunlar Nelerdir?",
      descriptions: [
        "Peteklerin ısınmaması, sistemin aşırı gürültülü çalışması ve su kaçakları, petek tesisatında sık karşılaşılan sorunlardandır. Bu sorunlar genellikle bakım eksikliğinden kaynaklanır.",
      ],
    },
  ];

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Petek Tesisatı Hakkında Bilgi
      </Typography>
      <List>
        {sections.map((section, index) => (
          <ListItem
            alignItems="flex-start"
            key={index}
            sx={{ borderBottom: 1, borderColor: "divider", py: 2 }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold" }}
                >
                  {section.title}
                </Typography>
              }
              secondary={
                <>
                  {section.descriptions.map((desc, descIndex) => (
                    <Typography
                      key={descIndex}
                      variant="body1"
                      component="p"
                      sx={{ mt: 1, color: theme.palette.text.primary }}
                    >
                      {desc}
                    </Typography>
                  ))}
                  {section.image && (
                    <CardMedia
                      component="img"
                      image={section.image}
                      alt={`Section Image ${index}`}
                      sx={{ width: "100%", mt: 2 }}
                    />
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default PetekTesisati;

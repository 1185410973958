import React from "react";
import { Typography, Paper, Link } from "@mui/material";

function Legal() {
  return (
    <Paper style={{ padding: "20px", marginBottom: "20px" }}>
      <Typography variant="h5">Yasal Uyarılar ve Gereklilikler</Typography>
      <Typography paragraph>
        Bu web sitesi ve tüm içerikleri,{" "}
        <Link href="https://www.regunenerji.com" underline="hover">
          Regun Enerji
        </Link>{" "}
        tarafından yönetilmekte ve işletilmektedir. Siteye erişim sağlayarak ve
        bu siteyi kullanarak, aşağıdaki şartlar ve koşulları kabul etmiş
        sayılırsınız.
      </Typography>
      <Typography paragraph>
        Tüm içerik, bilgi, yazılım, tasarım, grafikler, fotoğraflar ve
        materyaller ("İçerik") bu sitede telif hakkı, marka ve diğer fikri
        mülkiyet yasaları ile korunmaktadır. İçeriğin telif hakkı sahibi{" "}
        <Link href="https://www.regunenerji.com" underline="hover">
          Regun Enerji
        </Link>{" "}
        ve/veya lisans verenleridir. İçerik, yalnızca kişisel ve ticari olmayan
        kullanım için izin verilen şekilde kullanılabilir. İçeriğin herhangi bir
        bölümünü,{" "}
        <Link href="https://www.regunenerji.com" underline="hover">
          Regun Enerji
        </Link>
        'nin önceden yazılı izni olmaksızın çoğaltmak, kopyalamak, yeniden
        yayımlamak, yüklemek, göndermek, dağıtmak, satmak, ticari amaçlarla
        kullanmak veya başka bir şekilde suiistimal etmek yasaktır.
      </Typography>
      <Typography paragraph>
        Bu site ve içeriği "olduğu gibi" temelde sunulmaktadır ve{" "}
        <Link href="https://www.regunenerji.com" underline="hover">
          Regun Enerji
        </Link>{" "}
        site içeriğinin doğruluğu, eksiksizliği veya güncelliği konusunda
        herhangi bir garanti vermez.{" "}
        <Link href="https://www.regunenerji.com" underline="hover">
          Regun Enerji
        </Link>
        , bu sitede yer alan bilgilerin kullanılmasından kaynaklanabilecek
        herhangi bir zarar veya kayıp için sorumluluk kabul etmez.
      </Typography>
      <Typography paragraph>
        Bu web sitesi, kullanıcıların kolaylığı için diğer web sitelerine
        bağlantılar içerebilir.{" "}
        <Link href="https://www.regunenerji.com" underline="hover">
          Regun Enerji
        </Link>
        , bu sitelerin içeriği veya gizlilik politikaları üzerinde herhangi bir
        kontrol sahibi değildir ve bu sitelerin kullanımından kaynaklanabilecek
        herhangi bir zarardan sorumlu tutulamaz.
      </Typography>
      <Typography paragraph>
        <Link href="https://www.regunenerji.com" underline="hover">
          Regun Enerji
        </Link>
        , bu yasal uyarıları ve koşulları, web sitesinin politikalarını ve
        içeriğini önceden haber vermeksizin değiştirme hakkını saklı tutar. Site
        kullanıcıları, değişiklikleri takip etmekle yükümlüdür ve siteyi
        kullanmaya devam etmeleri, bu değişiklikleri kabul ettikleri anlamına
        gelir.
      </Typography>
    </Paper>
  );
}

export default Legal;

import React from "react";
import { Box, Typography, Button } from "@mui/material";

const UpcomingUpdates = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        margin: "20px 0",
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="h5"
        sx={{ textAlign: "center", marginBottom: "10px" }}
      >
        Ürünlerimiz Yakında Güncellenecektir!
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: "center", marginBottom: "20px" }}
      >
        Yeni ürünlerimiz ve özelliklerimiz hakkında güncel kalmak için bizi
        takip edin.
      </Typography>
      <Button variant="contained" color="primary" href="/urunler">
        Ürünlerimizi Keşfet
      </Button>
    </Box>
  );
};

export default UpcomingUpdates;

import React from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  CardMedia,
} from "@mui/material";
import theme from "../../../assets/createTheme";
// Havuz ısı pompası ile ilgili bir resim yolu ekleyin
import poolHeatPumpImage from "../../../assets/Hizmetler/havuz isi pompasi.jpg";

const HavuzIsiPompasi = () => {
  const sections = [
    {
      title: "Havuz Isı Pompası Servisi",
      descriptions: [
        "Havuz ısı pompası servisi, havuzlar için tasarlanmış ısı pompası sistemlerinin bakım, onarım ve kurulum hizmetlerini kapsar. Bu hizmet, havuz suyunun ideal sıcaklıkta tutulmasını sağlayarak, yıl boyunca konforlu bir yüzme deneyimi sunar.",
        "Havuz ısı pompaları, çevreden aldıkları ısıyı havuz suyuna aktararak çalışırlar. Enerji verimliliği yüksek olan bu sistemler, düşük işletme maliyetleri sayesinde tercih edilir.",
        "Düzenli servis ve bakım, havuz ısı pompasının verimli çalışmasını ve uzun ömürlü olmasını sağlar. Arızaların erken tespiti ve giderilmesi, olası büyük masrafların önüne geçer.",
      ],
      image: poolHeatPumpImage,
    },
  ];

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Havuz Isı Pompası Servisi Hakkında Bilgi
      </Typography>
      <List>
        {sections.map((section, index) => (
          <ListItem
            alignItems="flex-start"
            key={index}
            sx={{ borderBottom: 1, borderColor: "divider", py: 2 }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold" }}
                >
                  {section.title}
                </Typography>
              }
              secondary={
                <>
                  {section.descriptions.map((desc, descIndex) => (
                    <Typography
                      key={descIndex}
                      variant="body1"
                      component="p"
                      sx={{ mt: 1, color: theme.palette.text.primary }}
                    >
                      {desc}
                    </Typography>
                  ))}
                  {section.image && (
                    <CardMedia
                      component="img"
                      image={section.image}
                      alt={`Havuz Isı Pompası Resmi`}
                      sx={{ height: "600px", mt: 2 }}
                    />
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default HavuzIsiPompasi;

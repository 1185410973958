import React from "react";
import { Typography, Paper, Link } from "@mui/material";

function CookiePolicy() {
  return (
    <Paper style={{ padding: "20px", marginBottom: "20px" }}>
      <Typography variant="h5">Çerez Politikası</Typography>
      <Typography paragraph>
        Regun Enerji olarak, kullanıcılarımızın{" "}
        <Link href="https://www.regunenerji.com" underline="hover">
          www.regunenerji.com
        </Link>{" "}
        alan adlı internet sitemiz ve mobil uygulamamız üzerinden
        hizmetlerimizden güvenli ve eksiksiz şekilde faydalanmalarını sağlamak
        amacıyla, kişisel içerik ve reklamlar sunmak, site içi analitik
        faaliyetler gerçekleştirmek ve ziyaretçi kullanım alışkanlıklarını takip
        etmek için çerezler kullanılmaktadır. İşbu Çerez Politikası, Gizlilik
        Politikamızın ayrılmaz bir parçasıdır ve hangi çerezlerin
        kullanıldığını, kullanıcıların bu konudaki tercihlerini nasıl
        yönetebileceklerini açıklamaktadır.
      </Typography>
      <Typography paragraph>
        Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcınıza
        depolanan küçük metin dosyalarıdır ve isim, cinsiyet veya adres gibi
        kişisel verileri içermezler. Kullanılan çerezler, site performansını
        artırma, kullanım kolaylığı sağlama, kişiselleştirme ve reklamcılık
        faaliyetleri gibi amaçlar için farklı kategorilere ayrılmaktadır.
      </Typography>
      <Typography paragraph>
        Kullanıcılar, tarayıcı ayarları üzerinden çerez tercihlerini
        yönetebilirler. Ancak, Platform’un temel fonksiyonlarını gerçekleştirmek
        için zorunlu olan çerezlerin kapatılması, site bazı fonksiyonlarının
        beklenildiği gibi çalışmamasına neden olabilir. Çerez tercihlerinizle
        ilgili daha fazla bilgi ve ayarlar için, tarayıcınızın yardım menüsüne
        başvurabilirsiniz.
      </Typography>
      <Typography paragraph>
        6698 Sayılı Kişisel Verilerin Korunması Kanunu kapsamında çerezlerle
        ilgili haklarınızı kullanma ve daha fazla bilgi için,{" "}
        <Link href="/gizlilik-politikasi" underline="hover">
          Gizlilik Politikamızı
        </Link>{" "}
        ziyaret edebilirsiniz. Platformumuzda çerez kullanımına ilişkin
        tercihlerinizi her zaman değiştirebilirsiniz.
      </Typography>
      <Typography paragraph>
        Gizlilik ve Çerez Politikamız zaman zaman güncellenebilir. Güncel
        politika, platformumuzda yayınlandığı tarih itibarıyla yürürlük kazanır.
      </Typography>
    </Paper>
  );
}

export default CookiePolicy;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box, ThemeProvider, useMediaQuery } from "@mui/material";
import theme from "./assets/createTheme";
import Home from "./components/Pages/Home/Home";
import PrivacyPolicy from "./components/Pages/Footer/LegalLinks/PrivacyPolicy";
import Legal from "./components/Pages/Footer/LegalLinks/Legal";
import CustomerSatisfaction from "./components/Pages/Footer/LegalLinks/CustomerSatisfaction";
import CookiePolicy from "./components/Pages/Footer/LegalLinks/CookiePolicy";
import TermsAndConditions from "./components/Pages/Footer/LegalLinks/TermsAndConditions";
import Footer from "./components/Pages/Footer/Footer";
import Navbar from "./components/Pages/Navbar/Navbar";
import NavbarMobile from "./components/Pages/Navbar/NavbarMobile";
import NavbarTab from "./components/Pages/Navbar/NavbarTab";
import HeatPumpInfo from "./components/Pages/Isi Pompasi/HeatPumpInfo";
import MontajOncesiVeSonrasi from "./components/Pages/Isi Pompasi/MontajOncesiVeSonrasi";
import UcretsizKesif from "./components/Pages/OtherLinks/UcretsizKesif";
import IsiPompalari from "./components/Pages/Urunler/IsiPompalari";
import Klimalar from "./components/Pages/Urunler/Klimalar";
import Vrf from "./components/Pages/Vrf/Vrf";
import VrfNedir from "./components/Pages/Vrf/VrfNedir";
import IsiPompasi from "./components/Pages/Isi Pompasi/IsiPompasi";
import VrfSistemleri from "./components/Pages/Vrf/VrfSistemleri";
import Klima from "./components/Pages/Klima/Klima";
import Urunler from "./components/Pages/Urunler/Urunler";
import Zess from "./components/Pages/Urunler/Zess";
import WhatsAppButton from "./components/Pages/OtherLinks/WhatAppButton";
import Servis from "./components/Pages/OtherLinks/Servis";
import YerdenIsitma from "./components/Pages/OtherLinks/YerdenIsitma";
import PetekTesisati from "./components/Pages/OtherLinks/PetekTesisati";
import HavuzIsiPompasi from "./components/Pages/OtherLinks/HavuzIsiPompasi";
import Kampanyalar from "./components/Pages/OtherLinks/Kampanyalar";
import { useLocation } from "react-router-dom";
import SplashScreen from "./components/Pages/Home/SplashScreen";
import { useState } from "react";
import Cerezler from "./components/Pages/OtherLinks/Cerezler";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isWideScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [showSplashScreen, setShowSplashScreen] = useState(false);

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("isFirstVisit") === null;

    if (isFirstVisit) {
      setShowSplashScreen(true);
      localStorage.setItem("isFirstVisit", "false");

      const timer = setTimeout(() => {
        setShowSplashScreen(false);
      }, 1000); // 1 saniye sonra SplashScreen'ı gizle

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        {showSplashScreen && <SplashScreen />}
        <Box
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            overflowX: "hidden",
            pt: 0,
            position: "relative",
          }}
        >
          {isMobile ? (
            <NavbarMobile />
          ) : isWideScreen ? (
            <Navbar />
          ) : (
            <NavbarTab />
          )}
          <Box
            sx={{
              marginTop: {
                xs: "4rem",
                sm: "4rem",
                md: "4rem",
                lg: "1rem",
              },
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/yasal-uyarilar" element={<Legal />} />
              <Route
                path="/musteri-memnuniyeti"
                element={<CustomerSatisfaction />}
              />
              <Route
                path="/cerez-ve-gizlilik-politikasi"
                element={<CookiePolicy />}
              />
              <Route path="/kisisel-veriler" element={<PrivacyPolicy />} />
              <Route
                path="/kullanim-kosullari"
                element={<TermsAndConditions />}
              />

              <Route path="/ucretsiz-kesif" element={<UcretsizKesif />} />

              <Route path="/urunler" element={<Urunler />} />
              <Route path="/urunler/isi-pompalari" element={<IsiPompalari />} />
              <Route path="/urunler/klimalar" element={<Klimalar />} />
              <Route
                path="/urunler/vrf-sistemleri"
                element={<VrfSistemleri />}
              />

              <Route path="/isi-pompasi" element={<IsiPompasi />} />
              <Route
                path="/isi-pompasi/isi-pompalari"
                element={<IsiPompalari />}
              />
              <Route
                path="/isi-pompasi/isi-pompasi-nedir"
                element={<HeatPumpInfo />}
              />
              <Route
                path="/isipompasi/montaj-oncesi-ve-sonrasi-yapilmasi-gerekenler"
                element={<MontajOncesiVeSonrasi />}
              />

              <Route path="/klima" element={<Klima />} />
              <Route path="/klima/klimalar" element={<Klimalar />} />

              <Route path="/vrf" element={<Vrf />} />
              <Route path="/vrf/vrf-nedir" element={<VrfNedir />} />
              <Route path="/vrf/vrf-sistemleri" element={<VrfSistemleri />} />

              <Route
                path="/urunler/isi-pompalari/zess-silver-series"
                element={<Zess />}
              />

              <Route path="/havuz-isi-pompasi" element={<HavuzIsiPompasi />} />
              <Route path="/petek-tesisati" element={<PetekTesisati />} />
              <Route path="/yerden-isitma" element={<YerdenIsitma />} />
              <Route path="/servis" element={<Servis />} />
              <Route path="/kampanyalar" element={<Kampanyalar />} />
            </Routes>
            <WhatsAppButton phone={+905337794771} />
            <Cerezler />
            <Footer />
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
};

export default App;

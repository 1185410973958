import React from "react";
import { Typography, Paper } from "@mui/material";

function PrivacyPolicy() {
  return (
    <Paper style={{ padding: "20px", marginBottom: "20px" }}>
      <Typography variant="h5">
        Kişisel Verilerin Korunması ve İşlenmesi Politikası
      </Typography>
      <Typography paragraph>
        Regun Enerji olarak, kullanıcılarımızın hizmetlerimizden güvenli ve
        eksiksiz şekilde faydalanmalarını sağlamak amacıyla www.regunenerji.com
        alan adlı internet sitemizi ve mobil uygulamamızı kullanan üyelerimizin
        gizliliğini korumak için çalışıyoruz. Bu doğrultuda, işbu Gizlilik
        Politikası, üyelerimizin kişisel verilerinin 6698 sayılı Kişisel
        Verilerin Korunması Kanunu ile tamamen uyumlu bir şekilde işlenmesi ve
        kullanıcılarımızı bu bağlamda bilgilendirmek amacıyla hazırlanmıştır.
      </Typography>
      <Typography paragraph>
        Hangi Veriler İşlenmektedir? Kişisel Verilerin İşlenme Amacı Nedir?
        Kullanıcılarımıza daha iyi hizmet verebilmek amacıyla, adınız,
        soyadınız, e-posta adresiniz, telefon numaranız, cinsiyetiniz, doğum
        tarihiniz ve diğer tanımlayıcı bilgiler dahil olmak üzere birtakım
        kişisel verilerinizi işlemekteyiz. Ayrıca, site ziyaret sıklığı, sipariş
        zamanları gibi kişisel olmayan istatistiksel verileri de analiz etmek ve
        site güvenliğini güçlendirmek amacıyla toplamaktayız.
      </Typography>
      <Typography paragraph>
        Kişisel verileriniz, hizmetlerimizi sunmak, yasal yükümlülüklerimizi
        yerine getirmek ve sizlere daha iyi hizmetler sunabilmek amacıyla
        işbirliği yaptığımız üçüncü kişilerle paylaşılabilir. Kişisel veri
        sahipleri olarak, verilerinizin işlenip işlenmediğini öğrenme,
        işlenmişse bilgi alma, eksik veya yanlış işlenmişse düzeltilmesini talep
        etme ve işlenmesini gerektiren sebeplerin ortadan kalkması halinde
        verilerin silinmesini isteme haklarına sahipsiniz.
      </Typography>
      <Typography paragraph>
        Kişisel Veriler Ne Süreyle Saklanmaktadır? Veriler, yasal
        yükümlülüklerimizi yerine getirmek amacıyla belirli bir süreyle
        saklanmakta ve süre dolduğunda silinmekte, yok edilmekte veya anonim
        hale getirilmektedir. Kişisel verilerinizle ilgili haklarınızı kullanmak
        veya politikamız hakkında sorularınız için, web sitemizde yer alan
        iletişim bilgileri üzerinden bizimle iletişime geçebilirsiniz.
      </Typography>
    </Paper>
  );
}

export default PrivacyPolicy;

// ContactInfo.jsx
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const BIZEULASIN = [
  { name: "Çalıkuşu Mahallesi 3251 Sokak No.7/A" },
  { name: "KARABAĞLAR / İZMİR" },
  { name: "+90 533 779 47 71, +90 530 596 83 86" },
];

const ContactInfo = () => (
  <Box>
    {BIZEULASIN.map((item, index) => (
      <Typography key={index}>{item.name}</Typography>
    ))}
  </Box>
);

export default ContactInfo;

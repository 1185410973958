import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom"; // React Router'dan Link bileşenini import edin
import { links } from "./NavbarMenuList";

export default function NavbarMenu() {
  const [openMenu, setOpenMenu] = useState(null);
  const anchorRefs = useRef([]);
  const theme = useTheme(); // Temayı kullan

  const handleMouseEnter = (menuIndex) => {
    setOpenMenu(menuIndex);
  };

  const handleMouseLeave = () => {
    setOpenMenu(null);
  };

  return (
    <Stack direction="row" spacing={2} onMouseLeave={handleMouseLeave}>
      {links.map((link, index) => (
        <div key={index}>
          {link.submenu ? (
            <>
              <Button
                ref={(el) => (anchorRefs.current[index] = el)}
                id={`menu-button-${index}`}
                aria-controls={
                  openMenu === index ? `menu-list-${index}` : undefined
                }
                aria-haspopup="true"
                onMouseEnter={() => handleMouseEnter(index)}
                sx={{
                  color: theme.palette.text.secondary,
                  backgroundColor: "#0C2D48",
                  "&:hover": {
                    backgroundColor: "#F27127",
                    color: "#0C2D48",
                  },
                }}
              >
                {link.name}
              </Button>
              <Popper
                open={openMenu === index}
                anchorEl={anchorRefs.current[index]}
                role={undefined}
                transition
                disablePortal
                placement="bottom-start"
                sx={{ bgColor: "#4B4952" }}
              >
                {({ TransitionProps }) => (
                  <Grow {...TransitionProps}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleMouseLeave}>
                        <MenuList
                          autoFocusItem={openMenu === index}
                          id={`menu-list-${index}`}
                          aria-labelledby={`menu-button-${index}`}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                          sx={{
                            ".MuiMenuItem-root": {
                              color: theme.palette.text.primary,
                              "&:hover": {
                                backgroundColor: "#F27127",
                                color: "#0C2D48",
                              },
                            },
                          }}
                        >
                          {link.sublinks.map((sub, subIndex) =>
                            sub.sublink.map((item, itemIndex) => (
                              <MenuItem
                                key={itemIndex}
                                component={Link} // MenuItem'ı Link olarak kullan
                                to={item.link} // Link'in yönlendireceği yolu belirt
                                onClick={handleMouseLeave} // Menüyü kapat
                              >
                                {item.name}
                              </MenuItem>
                            ))
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          ) : (
            <Button
              ref={(el) => (anchorRefs.current[index] = el)}
              component={Link} // Button'ı Link olarak kullan
              to={link.link} // Link'in yönlendireceği yolu belirt
              sx={{
                color: theme.palette.text.secondary,
                backgroundColor: "#0C2D48",
                "&:hover": {
                  backgroundColor: "#F27127",
                  color: "#0C2D48",
                },
              }}
            >
              {link.name}
            </Button>
          )}
        </div>
      ))}
    </Stack>
  );
}

import React from "react";
import UpcomingUpdates from "../OtherLinks/UpcomingUpdates";

const Vrf = () => {
  return (
    <>
      <UpcomingUpdates />
    </>
  );
};

export default Vrf;

import React from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  CardMedia,
} from "@mui/material";
import theme from "../../../assets/createTheme";
// Hizmetlerle ilgili resimlerin yollarını ekleyin
import heatPumpImage from "../../../assets/Hizmetler/isi pompasi servis.jpg";
import acServiceImage from "../../../assets/Hizmetler/klima servisi.jpg";
import vrfSystemImage from "../../../assets/Hizmetler/vrf sistemleri servis.jpg";
import heatingProblemsImage from "../../../assets/Hizmetler/isinma 2.jpg";

const Servis = () => {
  const sections = [
    {
      title: "Isı Pompası Servisi",
      descriptions: [
        "Isı pompası servisi, ısı pompası sistemlerinin bakım, onarım ve kurulum hizmetlerini kapsar. Enerji verimliliği yüksek bu sistemlerin düzenli servisi, performansını uzun süre korumasını sağlar.",
      ],
      image: heatPumpImage,
    },
    {
      title: "Klima Servisi",
      descriptions: [
        "Klima servisi, ev ve iş yerlerindeki klima sistemlerinin bakımı, onarımı ve temizliği ile ilgilenir. Düzenli servis, klimanın verimli çalışmasını ve enerji tasarrufunu destekler.",
      ],
      image: acServiceImage,
    },
    {
      title: "VRF Sistemleri Servisi",
      descriptions: [
        "VRF sistemleri, büyük binalar için ideal olan gelişmiş klima sistemleridir. VRF sistemleri servisi, bu karmaşık sistemlerin bakımı, onarımı ve optimizasyonunu içerir.",
      ],
      image: vrfSystemImage,
    },
    {
      title: "Isınma Problemleri Servisi",
      descriptions: [
        "Isınma problemleri servisi, ısıtma sistemlerinin yetersiz ısıtması gibi sorunlarla ilgilenir. Bu hizmet, sistemlerin verimliliğini artırarak konforlu bir yaşam alanı sunar.",
      ],
      image: heatingProblemsImage,
    },
  ];

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Hizmetlerimiz
      </Typography>
      <List>
        {sections.map((section, index) => (
          <ListItem
            alignItems="flex-start"
            key={index}
            sx={{ borderBottom: 1, borderColor: "divider", py: 2 }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold" }}
                >
                  {section.title}
                </Typography>
              }
              secondary={
                <>
                  {section.descriptions.map((desc, descIndex) => (
                    <Typography
                      key={descIndex}
                      variant="body1"
                      component="p"
                      sx={{ mt: 1, color: theme.palette.text.primary }}
                    >
                      {desc}
                    </Typography>
                  ))}
                  {section.image && (
                    <CardMedia
                      component="img"
                      image={section.image}
                      alt={`Section Image ${index}`}
                      sx={{ height: "600px", mt: 2 }}
                    />
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default Servis;

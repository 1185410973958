import React from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import theme from "../../../assets/createTheme";

const MontajOnesiVeSonrasi = () => {
  const sections = [
    {
      title: "Montaj Öncesi Yapılması Gerekenler",
      descriptions: [
        "Alanın Hazırlanması: Montaj yapılacak alanın temiz, düz ve sabit bir yüzeye sahip olduğundan emin olun.",
        "Ekipman Kontrolü: İşi pompası ve montaj kitinde bulunan tüm parçaların eksiksiz ve hasarsız olduğunu kontrol edin.",
        "Sistem Gereksinimlerinin Doğrulanması: İşi pompasının kurulacağı sistemin basınç, sıcaklık, akış hızı gibi çalışma koşullarına uygun olduğunu doğrulayın.",
        "Güvenlik Önlemleri: Elektrikli aletler kullanılıyorsa, uygun güvenlik önlemlerinin alındığından emin olun.",
      ],
    },
    {
      title: "Montaj Sonrası Yapılması Gerekenler",
      descriptions: [
        "Sistem Testi: Montaj tamamlandıktan sonra, sistemi düşük basınçta test ederek herhangi bir sızıntı veya anormallik olup olmadığını kontrol edin.",
        "Ayarlamalar: Pompaya bağlı sistemlerde gerekiyorsa akış hızı, basınç gibi ayarlamaları yapın.",
        "Güvenlik Kontrolleri: Sistemin güvenli bir şekilde çalıştığından emin olun.",
        "Kullanım ve Bakım Talimatları: Pompayı kullanacak kişilere, cihazın doğru kullanımı ve bakımı hakkında bilgi verin.",
      ],
    },
  ];

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Isı Pompası Montajı Öncesi ve Sonrası Yapılması Gerekenler
      </Typography>
      <List>
        {sections.map((section, index) => (
          <ListItem
            alignItems="flex-start"
            key={index}
            sx={{ borderBottom: 1, borderColor: "divider", py: 2 }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold" }}
                >
                  {section.title}
                </Typography>
              }
              secondary={
                <>
                  {section.descriptions.map((desc, descIndex) => (
                    <Typography
                      key={descIndex}
                      variant="body1"
                      component="p"
                      sx={{ mt: 1, color: theme.palette.text.primary }}
                    >
                      {desc}
                    </Typography>
                  ))}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default MontajOnesiVeSonrasi;

import React from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  CardMedia,
} from "@mui/material";
import theme from "../../../assets/createTheme";
import splitImage from "../../../assets/NasilCalisir/split.jpg";
import FaqSection from "./FaqSection";

const HeatPumpInfo = () => {
  const sections = [
    {
      title: "Isı Pompası Nedir?",
      descriptions: [
        "Isı pompası, ısı enerjisini bir yerden alıp başka bir yere aktaran, genellikle elektrikle çalışan bir cihazdır.",
      ],
      // Resim eklemek için 'image' özelliğini kullanın
      image: splitImage, // Bu satırı her bölüm için uygun resim yolu ile değiştirin
    },
    {
      title: "Isı Pompası Nedir?",
      descriptions: [
        "Isı pompası, ısı enerjisini bir yerden alıp başka bir yere aktaran, genellikle elektrikle çalışan bir cihazdır. Isı pompaları, çevreden (hava, su veya toprak gibi) alınan ısıyı bir yere aktarır, enerjiyi doğrudan üretmek yerine var olan ısıyı kullanır. Çalışma prensibi, termodinamiğin dört temel sürecine dayanır: buharlaşma, sıkıştırma, yoğunlaşma ve genleşme. Isı pompaları, enerjiyi verimli bir şekilde taşıyarak ısıtma ve soğutma işlemlerinde kullanılır ve yüksek enerji verimliliği sağlar.",
      ],
    },
    {
      title: "Isı Pompasının Çeşitleri Nelerdir?",
      descriptions: [
        "Isı pompalarının çeşitleri arasında hava kaynaklı ısı pompaları (ASHP), su kaynaklı ısı pompaları ve toprak kaynaklı ısı pompaları (GSHP) bulunur. Bu çeşitlilik, kullanılacak enerji kaynağına göre belirlenir. Hava kaynaklı ısı pompaları, dış havadan alınan enerjiyi kullanırken; su kaynaklı sistemler, yer altı suyu gibi su kaynaklarından enerji alır. Toprak kaynaklı veya jeotermal ısı pompaları ise, yerin sabit sıcaklıklarından yararlanır. Her bir tip, bölgenin iklimi, mevcut enerji kaynakları ve kurulum için mevcut alan gibi faktörlere bağlı olarak seçilir.",
      ],
    },

    {
      title: "Isı Pompası Nasıl Çalışır?",
      descriptions: [
        "Monoblok ve Split ısı pompaları, tüm ana bileşenlerin (evaporatör, kompresör, kondanser ve genleşme vanası) tek bir dış ünite içinde entegre edildiği bir sistemdir. Bu tasarım, iç mekanda yer tasarrufu sağlar ve kurulumu basitleştirir. İşleyiş adımları şu şekildedir:",
        "- Isı Emme: Dış mekan ünitesindeki evaporatör, çevreden ısıyı emer. Düşük basınçtaki soğutucu akışkan, buharlaşırken çevre ısısını absorbe eder.",
        "- Kompresyon: Buharlaşan soğutucu akışkan, ünitenin içindeki kompresöre yönlendirilir. Kompresör, akışkanın basıncını ve dolayısıyla sıcaklığını artırır.",
        "- Isı Transferi: Yüksek basınçlı ve sıcaklıkta soğutucu akışkan, kondansere ulaşır. Burada, ısı iç mekana taşınacak suya veya havaya aktarılırken, soğutucu akışkan yoğunlaşır ve sıvı hale döner.",
        "- Basınç Düşürme: Sıvı haline dönen soğutucu akışkan, genleşme vanasından geçerek basıncı düşürülür ve tekrar buharlaşmaya hazır hale gelir. Bu döngü sürekli tekrarlanır.",
      ],
    },
    {
      title: "Monoblok Isı Pompası Nasıl Çalışır?",
      descriptions: [
        "Monoblok ısı pompaları, özellikle basit kurulumu ve kompakt tasarımı ile dikkat çeker. Dış mekanda kurulurlar ve ısıtma veya soğutma ihtiyacı olan iç mekana ısı transferi için su bazlı sistemler aracılığıyla bağlantı kurarlar.",
      ],
    },
    {
      title: "Split Isı Pompası Nasıl Çalışır?",
      descriptions: [
        "Split ısı pompaları, esnek kurulum seçenekleri ve iç mekan ile dış mekan arasında etkili bir ısı transferi sağlama kapasitesi ile öne çıkar. İç mekan ünitesi genellikle duvara monte edilirken, dış mekan ünitesi evin dışında uygun bir yere konumlandırılır. Bu tasarım, iç mekanda yer tasarrufu sağlar ve estetik bir görünüm sunar.",
      ],
    },
    {
      title: "Isı Pompasının Avantajları Nelerdir?",
      descriptions: [
        "Isı pompaları, düşük maliyetle yüksek oranda enerji kullanımı sağlar, sessiz çalışır, atık gaz salınımı yapmaz ve %75'e varan oranda enerji tasarrufu sunar.",
      ],
    },
    {
      title: "Isı Pompası Sistemi Nerelerde Kullanılır?",
      descriptions: [
        "Konutlar, hizmet binaları, oteller, AVM'ler, sanayi sektörü ve havuzlu yapılar gibi birçok alanda kullanılır.",
      ],
    },
  ];

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Isı Pompası Hakkında Bilgi
      </Typography>
      <List>
        {sections.map((section, index) => (
          <ListItem
            alignItems="flex-start"
            key={index}
            sx={{ borderBottom: 1, borderColor: "divider", py: 2 }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold" }}
                >
                  {section.title}
                </Typography>
              }
              secondary={
                <>
                  {section.descriptions.map((desc, descIndex) => (
                    <Typography
                      key={descIndex}
                      variant="body1"
                      component="p"
                      sx={{ mt: 1, color: theme.palette.text.primary }}
                    >
                      {desc}
                    </Typography>
                  ))}
                  {/* Resim gösterimi */}
                  {section.image && (
                    <CardMedia
                      component="img"
                      image={section.image}
                      alt={`Section Image ${index}`}
                      sx={{ width: "100%", mt: 2 }}
                    />
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
      <FaqSection />
    </Container>
  );
};

export default HeatPumpInfo;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/LogoForSplashScreen.svg";
import theme from "../../../assets/createTheme";

function SplashScreen() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      navigate("/"); // Ana sayfanın rotasına yönlendir
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);

  if (!isVisible) return null;

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
        background: theme.palette.background2.default,
        // Logo'nun boyutunu ekran genişliğine bağlı olarak ayarla
        overflow: "hidden",
      }}
    >
      <img
        src={Logo}
        alt="Regun Enerji"
        style={{
          maxWidth: "80%",
          maxHeight: "80%",
          width: "auto",
          height: "auto",
        }}
      />
    </div>
  );
}

export default SplashScreen;

import React, { useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Container,
  Dialog,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import zessSilver from "../../../assets/UrunFoto/zess isi pompasi silver series.jpg";
import zessGold from "../../../assets/UrunFoto/zess isi pompasi gold series sade kopya 2.jpg";

const Urunler = () => {
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const handleOpenPdf = (url) => {
    setPdfUrl(url);
    setOpenPdf(true);
  };

  const handleClosePdf = () => {
    setOpenPdf(false);
    setPdfUrl("");
  };

  const urunler = [
    {
      id: 1,
      adi: "Zess Gold Series",
      resim: zessGold,
      pdfLink: "https://www.regunenerji.com/katalog.pdf",
    },
    {
      id: 2,
      adi: "Zess Silver Series",
      resim: zessSilver,
      pdfLink: "https://www.regunenerji.com/katalog.pdf",
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2} justifyContent="center">
        {urunler.map((urun) => (
          <Grid item xs={12} sm={6} md={4} key={urun.id}>
            <Card
              sx={{
                maxWidth: 345,
                bgcolor: "background.paper",
                ":hover": {
                  boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                },
                transition: "0.3s",
              }}
            >
              <CardActionArea onClick={() => handleOpenPdf(urun.pdfLink)}>
                <CardMedia
                  component="img"
                  height="300"
                  image={urun.resim}
                  alt={urun.adi}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: "bold" }}
                  >
                    {urun.adi}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* PDF Dialog */}
      <Dialog open={openPdf} onClose={handleClosePdf} fullWidth maxWidth="xl">
        <IconButton
          onClick={handleClosePdf}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <iframe
          title="Zess Isı Pompası"
          src={pdfUrl}
          frameBorder="0"
          width="100%"
          height="600px"
          allowFullScreen
        ></iframe>
      </Dialog>
    </Container>
  );
};

export default Urunler;

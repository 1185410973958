import React from "react";
import { Box, CssBaseline, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom"; // Link bileşeni import edilir
import ContactInfo from "./ContactInfo";
import Services from "./Services";
import LegalLinks from "./LegalLinks"; // Eğer LegalLinks içinde Link kullanılacaksa, orada da benzer değişiklikler yapılmalıdır.
import SocialIcons from "./SocialIcons";
import theme from "../../../assets/createTheme";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        mt: 4,
        py: 2,
        px: 3,
        textAlign: "center",
        backgroundColor: theme.palette.background2.default,
      }}
    >
      <CssBaseline />
      <Grid
        container
        spacing={2}
        sx={{
          width: "100%",
          backgroundColor: theme.palette.background2.default,
          textDecoration: "none",
          color: theme.palette.text.primary,
        }}
      >
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="div" gutterBottom>
            Bize Ulaşın
          </Typography>
          <ContactInfo />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="div" gutterBottom>
            Hizmetler
          </Typography>
          <Services />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="div" gutterBottom>
            Yasal İçerikler
          </Typography>
          <LegalLinks />{" "}
          {/* LegalLinks içinde Link kullanılacaksa güncellenmelidir */}
        </Grid>
        <Grid item xs={12}>
          <SocialIcons />
          <Typography
            variant="body1"
            component="div"
            sx={{
              color: "primary.main",
              mt: 2,
            }}
          >
            © Regun Enerji, 2024
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;

import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import petekTesisati from "../../../assets/Hizmetler/petek tesisati.jpg";
import yerdenIsitma from "../../../assets/Hizmetler/yerden isitma.jpg";
import petekTemizligi from "../../../assets/Hizmetler/petek temizligi.jpg";

const HizmetlerimizYenidenYapilandirildi = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "16px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: theme.palette.background.white,
      color: theme.palette.text.secondary,
    },
  };

  const actionStyle = {
    mt: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  };

  const items = [
    {
      title: "Petek Tesisatı",
      content:
        "Evinizi etkili bir şekilde ısıtmak için petek tesisatının doğru bir şekilde kurulması ve bakımının yapılması gerekmektedir. Profesyonel ekiplerimiz, verimli ve güvenilir petek tesisatı hizmetleri sunar.",
      img: petekTesisati,
      link: "/petek-tesisati",
    },
    {
      title: "Yerden Isıtma",
      content:
        "Yerden ısıtma sistemleri, mekanınızı eşit bir şekilde ısıtarak konforlu bir yaşam alanı sağlar. Uzman ekibimiz, yerden ısıtma sistemlerinin kurulum ve bakımında size yardımcı olur.",
      img: yerdenIsitma,
      link: "/yerden-isitma",
    },
    {
      title: "Tesisat Temizliği",
      content:
        "Tesisatınızın düzenli temizlenmesi, verimliliğini artırır ve olası arızaların önüne geçer. Profesyonel tesisat temizleme hizmetlerimiz ile sistemlerinizin ömrünü uzatın.",
      img: petekTemizligi,
      link: "/servis",
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, mt: 4, mb: 4 }}>
      <Grid container spacing={isSmallScreen ? 2 : 4} justifyContent="center">
        {items.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={cardStyle}>
              <CardMedia
                component="img"
                height="300"
                image={item.img}
                alt={item.title}
              />
              <CardContent>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  sx={{ fontWeight: "600" }}
                >
                  {item.title}
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  {item.content}
                </Typography>
              </CardContent>
              <Box sx={actionStyle}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={item.link}
                >
                  Daha Fazla Bilgi
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HizmetlerimizYenidenYapilandirildi;

import React from "react";
import { ThemeProvider } from "@mui/material/styles"; // ThemeProvider'ı import edin
import { Box } from "@mui/material";
import theme from "../../../assets/createTheme"; // Temayı doğrudan içe aktarın
import Carousel from "./Carousel";
import HomePageContent from "./HomaPageContent";
import Hizmetlerimiz from "./Hizmetlerimiz";

const Home = () => {
  // useTheme hook'u kullanılmıyor, doğrudan içe aktarılan theme kullanılıyor.

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: 3 }}>
        <Carousel />
      </Box>
      <Box sx={{ mt: 4 }}>
        <HomePageContent />
        <Hizmetlerimiz />
      </Box>
    </ThemeProvider>
  );
};

export default Home;

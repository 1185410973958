import React from "react";
import { Grid } from "@mui/material";
import greeAphro from "../../../assets/UrunFoto/Gree Klima/GreeAphro.png";
import greeAphro2 from "../../../assets/UrunFoto/Gree Klima/GreeAphro2.png";
import pular from "../../../assets/UrunFoto/Gree Klima/Gree-Pular-Inverter-Duvar-Tipi-Klima.png";
import gTech from "../../../assets/UrunFoto/Gree Klima/G-Tech-Duvar-Tipi-Inverter-Klima-Silver2.png";
import fairy from "../../../assets/UrunFoto/Gree Klima/Gree-Fairy-Duvar-Tipi-Inverter-Klima-1.png";
import uCrown from "../../../assets/UrunFoto/Gree Klima/U-Crown-Cropped-4.jpg";
import uCrown1 from "../../../assets/UrunFoto/Gree Klima/U-Crown-Cropped-1.jpg";
import uCrown2 from "../../../assets/UrunFoto/Gree Klima/U-Crown-Cropped-5.jpg";
import uCrown3 from "../../../assets/UrunFoto/Gree Klima/U-Crown-Cropped-3.jpg";
import emura from "../../../assets/UrunFoto/Daikin Klima/Daikin Emura.PNG";
import ururu from "../../../assets/UrunFoto/Daikin Klima/Daikin Ururu Sarara.PNG";
import sensira from "../../../assets/UrunFoto/Daikin Klima/Sensira.PNG";
import shiraPlus from "../../../assets/UrunFoto/Daikin Klima/Shira.PNG";
import shiraEco from "../../../assets/UrunFoto/Daikin Klima/Shira Eco.PNG";
import stylish1 from "../../../assets/UrunFoto/Daikin Klima/Stylish 1.PNG";
import stylish2 from "../../../assets/UrunFoto/Daikin Klima/Stylish 2.PNG";
import zetaShiraPlus from "../../../assets/UrunFoto/Daikin Klima/Zeta Shira Plus.PNG";
import ProductItem from "../Klima/KlimaList";
import gTech1 from "../../../assets/UrunFoto/Gree Klima/G-Tech-Duvar-Tipi-Inverter-Klima-Silver.png";
import fairy2 from "../../../assets/UrunFoto/Gree Klima/Gree-Fairy-Duvar-Tipi-Inverter-Klima2.png";
import fairy3 from "../../../assets/UrunFoto/Gree Klima/Gree-Fairy-Duvar-Tipi-Inverter-Klima3.png";

const productData = [
  {
    model: "Gree Aphro Inverter Klima",
    tip: ["Split Klima"],
    btuKapasiteleri: ["9000", "12000", "18000", "24000"],
    enerjiSinifi: "A++",
    foto: [greeAphro, greeAphro2],
  },
  {
    model: "Gree Pular Inverter Klima",
    tip: ["Split Klima", "Multi Klima"],
    btuKapasiteleri: ["9000", "12000", "18000", "24000"],
    enerjiSinifi: "A++",
    foto: [pular],
  },
  {
    model: "Gree Fairy Inverter Klima",
    tip: ["Split Klima", "Multi Klima"],
    btuKapasiteleri: ["9000", "12000", "18000", "24000"],
    enerjiSinifi: "A++",
    foto: [fairy, fairy2, fairy3],
  },
  {
    model: "Gree U-Crown Inverter Klima",
    tip: ["Split Klima"],
    btuKapasiteleri: ["9000", "12000", "18000", "24000"],
    enerjiSinifi: "A++",
    foto: [uCrown, uCrown1, uCrown2, uCrown3],
  },
  {
    model: "Gree G-Tech Inverter Klima",
    tip: ["Split Klima"],
    btuKapasiteleri: ["9000", "12000", "18000", "24000"],
    enerjiSinifi: "A+++",
    foto: [gTech, gTech1],
  },
  {
    model: "Daikin Emura Inverter Klima",
    tip: ["Split Klima", "Multi Klima"],
    btuKapasiteleri: ["7000", "9000", "1200", "14000", "18000"],
    enerjiSinifi: "A+++",
    foto: [emura],
  },
  {
    model: "Daikin Ururu Sarara",
    tip: ["Split Klima", "Multi Klima"],
    btuKapasiteleri: ["9000", "12000", "18000"],
    enerjiSinifi: "A+++",
    foto: [ururu],
  },
  {
    model: "Daikin Sensira",
    tip: ["Split Klima", "Multi Klima"],
    btuKapasiteleri: ["7000", "9000", "1200", "14000", "22000", "24000"],
    enerjiSinifi: "A++",
    foto: [sensira],
  },
  {
    model: "Gree Aphro ",
    tip: ["Split Klima", "Multi Klima"],
    btuKapasiteleri: ["9000", "12000", "18000", "24000"],
    enerjiSinifi: "A++",
    foto: [shiraEco],
  },
  {
    model: "Daikin Shira Plus",
    tip: ["Split Klima", "Multi Klima"],
    btuKapasiteleri: ["7000", "9000", "1200", "14000", "22000", "24000"],
    enerjiSinifi: "A+++",
    foto: [shiraPlus],
  },
  {
    model: "Daikin Stylish",
    tip: ["Split Klima", "Multi Klima"],
    btuKapasiteleri: ["9000", "12000", "18000"],
    enerjiSinifi: "A+++",
    foto: [stylish1, stylish2],
  },
  {
    model: "Daikin Zeta Shira Plus",
    tip: ["Split Klima", "Multi Klima"],
    btuKapasiteleri: ["7000", "9000", "1200", "14000", "18000"],
    enerjiSinifi: "A+++",
    foto: [zetaShiraPlus],
  },
];

function Products() {
  return (
    <Grid container spacing={2}>
      {productData.map((product, index) => (
        <ProductItem key={index} product={product} index={index} />
      ))}
    </Grid>
  );
}

export default Products;

// SocialIcons.jsx
import React from "react";
import { Box, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const SocialIcons = () => (
  <Box>
    <IconButton
      aria-label="Facebook"
      component="a"
      href="#"
      sx={{
        color: "primary.main",
        "&:hover": {
          color: "text.secondary",
          textDecoration: "none",
        },
      }}
    >
      <FacebookIcon />
    </IconButton>
    <IconButton
      aria-label="Twitter"
      component="a"
      href="#"
      sx={{
        color: "primary.main",
        "&:hover": {
          color: "text.secondary",
          textDecoration: "none",
        },
      }}
    >
      <TwitterIcon />
    </IconButton>
    <IconButton
      aria-label="LinkedIn"
      component="a"
      href="#"
      sx={{
        color: "primary.main",
        "&:hover": {
          color: "text.secondary",
          textDecoration: "none",
        },
      }}
    >
      <LinkedInIcon />
    </IconButton>
    <IconButton
      aria-label="Instagram"
      component="a"
      href="#"
      sx={{
        color: "primary.main",
        "&:hover": {
          color: "text.secondary",
          textDecoration: "none",
        },
      }}
    >
      <InstagramIcon />
    </IconButton>
  </Box>
);

export default SocialIcons;

// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    blue: {
      main: "#0C2D48", // Ana renk
    },
    ice: {
      orange: "#F27127", // İkincil renk
    },
    red: {
      main: "#D93223", // İkincil renk
    },
    black: {
      main: "#0D0D0D", // İkincil renk
    },
    error: {
      main: "#8C1616", // Hata mesajları için kırmızı renk
    },
    background: {
      default: "#BABDBF", // Varsayılan arka plan rengi
      white: "#fff",
    },
    background2: {
      default: "#adb3b5 ", // Varsayılan arka plan rengi
    },
    text: {
      primary: "#0C2D48", // Ana metin rengi
      secondary: "#F27127", // İkincil metin rengi
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "bold", // Buton metinlerini kalın yapar
        },
        containedPrimary: {
          backgroundColor: "#F27127", // Ana renk buton arka planı
          color: "#0C2D48",
          "&:hover": {
            backgroundColor: "#0C2D48", // Hover durumu için daha koyu bir mavi
            color: "#F27127",
          },
        },
        containedSecondary: {
          backgroundColor: "#0C2D48", // Ana renk buton arka planı
          color: "#F27127",
          "&:hover": {
            backgroundColor: "#F27127", // Hover durumu için daha koyu bir mavi
            color: "#0C2D48",
          },
        },
      },
    },
  },
});

export default theme;

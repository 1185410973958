import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Paper, useTheme } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { links } from "./NavbarMenuList"; // Import Link from react-router-dom

export default function MenuAccordion({ closeMenu }) {
  const theme = useTheme(); // Temayı kullanmak için

  const handleLinkClick = () => {
    closeMenu(); // Menüyü kapatma işlemi
  };

  return (
    <div>
      {links.map((link, index) => {
        if (link.submenu) {
          return (
            <Accordion key={index} sx={{ position: "relative", zIndex: 9999 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
                sx={{ fontWeight: "bold" }}
              >
                {link.name}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  ul: {
                    padding: 0,
                    margin: 0,
                    listStyle: "none",
                  },
                  "& li": {
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  },
                  "& a": {
                    textDecoration: "none",
                    color: "inherit",
                    fontWeight: "normal",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  },
                }}
              >
                <ul>
                  {link.sublinks[0].sublink.map((sublink, subIndex) => (
                    <li key={subIndex}>
                      <Link
                        to={sublink.link}
                        style={{ textDecoration: "none", color: "inherit" }}
                        onClick={handleLinkClick} // Bu kısma `onClick` olayını ekleyin
                      >
                        {sublink.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
          );
        } else {
          return (
            <Paper
              elevation={2}
              key={index}
              sx={{
                position: "relative",
                zIndex: 2,
                padding: "10px 20px",
                borderBottom: "1px solid #e0e0e0",
                textAlign: "left",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <Link
                to={link.link}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "block",
                  height: "100%",
                }}
                onClick={handleLinkClick} // Ve burada da `onClick` olayını ekleyin
              >
                {link.name}
              </Link>
            </Paper>
          );
        }
      })}
      <Button
        component={Link}
        to="/ucretsiz-kesif" // Change this to your form's route
        onClick={handleLinkClick}
        fullWidth
        variant="contained"
        color="primary"
        style={{ margin: theme.spacing(2, 0) }}
      >
        Ücretsiz Keşif Formu
      </Button>
    </div>
  );
}

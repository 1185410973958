import React, { useState } from "react";
import { TextField, Button, Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../assets/createTheme"; // Adjust the path as necessary

const MyComponent = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(phoneNumber)) {
      return; // Prevent submission if phone number is invalid
    }
    // Replace placeholders with your actual Mailgun credentials
    const apiKey = "6a8976a7821bfe022fc6aa3b7c59eff6-408f32f3-41e7c7cc";
    const domain = "sandboxa3cf6f8151934a99932793b5743cfbb7.mailgun.org";
    const formData = new FormData();
    formData.append("from", "info@regunenerji.com");
    formData.append("to", "veysel@regunenerji.com");
    formData.append("subject", "Ücretsiz Keşif Formu Talebi");
    formData.append(
      "text",
      `
      İsim: ${name}
      Telefon Numarası: ${phoneNumber}
      Mesaj: ${message}
    `
    );

    try {
      const response = await fetch(
        `https://api.mailgun.net/v3/${domain}/messages`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${btoa(`api:${apiKey}`)}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Mailgun API request failed");
      }

      // Form reset and success message
      setName("");
      setPhoneNumber("");
      setMessage("");
      alert("Mesajınız başarıyla gönderildi!");
    } catch (error) {
      console.error("Error sending email:", error);
      alert(
        "Mesajınız gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin."
      );
    }
  };
  const validatePhoneNumber = (phoneNumber) => {
    // Türkiye için standart mobil telefon numarası formatı: 5XX XXX XXXX
    // Önce tüm boşlukları ve parantez, tire gibi karakterleri kaldırır
    const formattedNumber = phoneNumber.replace(/\D/g, "");

    // Telefon numarasının 10 rakamdan oluştuğunu ve 5 ile başladığını kontrol eder
    const isValid =
      formattedNumber.length === 10 && formattedNumber.startsWith("5");

    setPhoneNumberError(!isValid);
    setPhoneNumberHelperText(
      isValid
        ? ""
        : "Lütfen geçerli bir telefon numarası giriniz (5XX XXX XXXX)"
    );

    return isValid;
  };

  const handlePhoneNumberChange = (e) => {
    const rawValue = e.target.value;
    // Kullanıcının sadece rakam girmesini sağlar
    const formattedValue = rawValue.replace(/\D/g, "");

    // Girilen değeri doğrudan setPhoneNumber ile günceller
    // Formatlama ihtiyacı olmadan doğrudan rakamları alır
    setPhoneNumber(formattedValue);
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Girilen telefon numarasının formatını düzeltir: 5XX XXX XXXX
    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `${match[1]} ${match[2]} ${match[3]}`;
    }
    return phoneNumber;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm" style={{ padding: theme.spacing(3) }}>
        {" "}
        {/* Ekstra padding ve maxWidth ile daha dar ve şık bir form */}
        <div
          style={{
            backgroundColor: theme.palette.background.default,
            padding: "20px",
            borderRadius: "8px", // Köşeleri yuvarlak hale getirir
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", // Hafif bir gölge ekler
          }}
        >
          <h1
            style={{ color: theme.palette.text.primary, textAlign: "center" }}
          >
            Ücretsiz Keşif Formu
          </h1>
          <form
            onSubmit={handleSubmit}
            style={{ color: theme.palette.text.primary }}
          >
            <TextField
              id="name"
              name="name"
              label="İsim"
              variant="outlined"
              fullWidth
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ marginBottom: "20px" }}
            />

            <TextField
              id="phoneNumber"
              name="phoneNumber"
              label="Telefon Numaranız"
              variant="outlined"
              fullWidth
              required
              error={phoneNumberError}
              helperText={phoneNumberHelperText}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              onBlur={() => validatePhoneNumber(phoneNumber)}
              style={{ marginBottom: "20px" }}
            />

            <TextField
              id="message"
              name="message"
              label="Mesajınız"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              style={{ marginBottom: "20px" }}
            />

            <Button variant="contained" color="primary" type="submit" fullWidth>
              Gönder
            </Button>
          </form>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default MyComponent;

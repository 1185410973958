import React from "react";
import { Typography, Paper, Link } from "@mui/material";

function CustomerSatisfaction() {
  return (
    <Paper style={{ padding: "20px", marginBottom: "20px" }}>
      <Typography variant="h5">Müşteri Memnuniyeti</Typography>
      <Typography paragraph>
        Biz, Regun Enerji olarak, müşterilerimizin memnuniyetini en üst düzeyde
        tutmayı amaç edinmiş bir şirketiz. Sunduğumuz ürün ve hizmetlerle ilgili
        her türlü geri bildiriminizi büyük bir ciddiyetle değerlendiriyor ve bu
        geri bildirimler doğrultusunda kendimizi sürekli geliştiriyoruz. Müşteri
        memnuniyeti politikamız, kaliteli hizmet anlayışımızın ve müşteri odaklı
        yaklaşımımızın bir yansımasıdır.
      </Typography>
      <Typography paragraph>
        Geri bildirimleriniz için çeşitli kanallarımız bulunmaktadır. İster web
        sitemiz üzerinden{" "}
        <Link
          href="https://www.regunenerji.com/geri-bildirim"
          underline="hover"
        >
          geri bildirim formumuzu
        </Link>{" "}
        kullanarak, ister doğrudan müşteri hizmetleri temsilcilerimizle
        iletişime geçerek düşüncelerinizi bizimle paylaşabilirsiniz. Tüm geri
        bildirimler, müşteri memnuniyeti ekibimiz tarafından dikkatle incelenir
        ve gerekli görüldüğünde sizinle iletişime geçilir.
      </Typography>
      <Typography paragraph>
        Müşteri memnuniyeti, bizim için sadece bir hedef değil, aynı zamanda bir
        yolculuktur. Bu yolculukta siz değerli müşterilerimizin görüşleri, bizim
        için en önemli rehberdir. Sizlere daha iyi hizmet sunabilmek için
        çalışmalarımızı aralıksız sürdürmekteyiz.
      </Typography>
      <Typography paragraph>
        Kaliteli hizmet sunma konusunda kararlıyız ve müşteri memnuniyetini
        sürekli olarak artırmak için yenilikçi çözümler geliştirmeye devam
        edeceğiz. Bizi tercih ettiğiniz için teşekkür eder, her türlü öneri,
        görüş ve şikayetlerinizle bizi daha iyiye taşıyacağınıza inanıyoruz.
      </Typography>
    </Paper>
  );
}

export default CustomerSatisfaction;

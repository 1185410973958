export const links = [
  {
    name: "Anasayfa",
    submenu: false,
    link: "/",
  },
  {
    name: "Ürünler",
    submenu: true,
    sublinks: [
      {
        sublink: [
          { name: "Isı Pompaları", link: "/urunler/isi-pompalari" },
          { name: "Klimalar", link: "/urunler/klimalar" },
          { name: "VRF Sistemleri", link: "/urunler/vrf-sistemleri" },
        ],
      },
    ],
  },
  {
    name: "Isı Pompası",
    submenu: true,
    sublinks: [
      {
        sublink: [
          {
            name: "Isı Pompası Nedir ?",
            link: "/isi-pompasi/isi-pompasi-nedir",
          },
          { name: "Isı Pompaları", link: "/isi-pompasi/isi-pompalari" },
          {
            name: "Montaj Öncesi ve Sonrası",
            link: "/isipompasi/montaj-oncesi-ve-sonrasi-yapilmasi-gerekenler",
          },
        ],
      },
    ],
  },
  {
    name: "Klima",
    submenu: true,
    sublinks: [
      {
        sublink: [{ name: "Klimalar", link: "/klima/klimalar" }],
      },
    ],
  },
  {
    name: "Vrf",
    submenu: true,
    sublinks: [
      {
        sublink: [
          { name: "VRF Sistemi Nedir ?", link: "/vrf/vrf-nedir" },
          { name: "VRF Sistemleri", link: "/vrf/vrf-sistemleri" },
        ],
      },
    ],
  },
];

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FaqSection = () => {
  const faqs = [
    {
      question: "Isı pompası evi ısıtır mı?",
      answer:
        "Isı pompaları, yazı evi soğutma kışın ise evi ısıtma gücü vardır. Doğalgaz, soba ya da elektrikli ısıtıcılar gibi aynı şekilde ısıtmaktadır.",
    },
    {
      question: "Isı pompaları sistemi ne kadar elektrik harcar?",
      answer:
        "Bu sorunun net bir cevabı yoktur. Çünkü ısı pompalarının ne kadar elektrik harcadığını kullanılan ürün, iklim koşulları, izolasyon durumu ve sıcaklık su değeri vs. etkilemektedir.",
    },
    {
      question: "Isı pompalarının modelleri nelerdir?",
      answer:
        "Isı pompaları kendi içerisinde 3’e ayrılır. Bunlar; hava kaynaklı, toprak kaynaklı ve su kaynaklıdır.",
    },
    {
      question: "Isı pompalarından ne kadar tasarruf sağlanır?",
      answer: "Elektrik tüketiminde ortalama %40 tasarruf edilebilmektedir.",
    },
    {
      question: "Isı pompası alımında dikkat edilmesi gerekenler nelerdir?",
      answer:
        "Birincisi, ısıtılacak ya da soğutulacak bölgenin ölçümü iyi yapılmalı. İkincisi, bölgeyi ısıtacak kapasitede ısı pompası modeli tercih edilmeli. Üçüncüsü, ısı pompasının kullanıldığı bölgenin ısı yalıtım özelliğine sahip olup olmadığı denetlenmelidir.",
    },
  ];

  return (
    <Container maxWidth="md" sx={{ marginTop: 3 }}>
      <Typography variant="h4" gutterBottom>
        Sıkça Sorulan Sorular
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default FaqSection;
